import styled from "styled-components";
import styles from "./style.module.css"

const Container = styled.div``;

const Wrapper = styled.div`
`;

const ImgContainer = styled.div`
`;

const Image = styled.img`
`;

const InfoContainer = styled.div`
`;

const Title = styled.h1`
  font-weight: 300;
`;

const Desc = styled.p`
  margin: 20px 0px;
`;

const Price = styled.span`
  font-weight: 200;
  font-size: 26px;
`;

const AddContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled.button`
  margin-top: 26px;
  padding: 20px;
  border: 2px solid teal;
  background-color: white;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  &:hover{
      background-color: #f8f4f4;
  }
`;

const NewLine = styled.div`
  white-space: pre-wrap;
`;


const HybridHerre2 = () => {
  return (
    <Container>
      <Wrapper className={styles.wrapper}>
          <Image src="https://stensgata-sykkel.no/images/hybrid/Merida-hybrid_herre2.png" className={styles.image}/>
        <InfoContainer className={styles.infoContainer}>
          <Title>Merida CrossWay 52cm ramme</Title>
          <Desc>
          Velkjørende kvalitets-sykkel. Gir og bremser fungerer bra. Sykkelen har fått service og er klar til bruk. 
          Passer til en på ca. 167-185cm. 14 dagers returrett. 
          </Desc>
          <Desc>
          <NewLine>52cm ramme</NewLine>
          <NewLine>28” hjul</NewLine> 
          <NewLine>24 Shimano Acera gir</NewLine> 
          <NewLine>Støtteben</NewLine>
          </Desc>
          <Price>NOK 1200</Price>
          <AddContainer>
            <Button><a href="tel:92292909">Ta kontakt for avtale</a></Button>
          </AddContainer>
        </InfoContainer>
      </Wrapper>
    </Container>
  );
};

export default HybridHerre2;