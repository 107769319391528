import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import styled from 'styled-components';
import CookieConsent from "react-cookie-consent";
import Home from "./pages/Home";
import Sykler from "./pages/Sykler";
import Herresykler from "./pages/Herresykler";
import Damesykler from "./pages/Damesykler";
import Verksted from "./pages/Verksted";
import Deler from "./pages/Deler";
import Henting from "./pages/Henting";
import Kontakt from "./pages/Kontakt";
import Product from "./pages/Product";
import Product2 from "./pages/Product2";
import HybridHerre1 from "./pages/Sykler/Hybrid/Herre/HybridHerre1";
import HybridHerre2 from "./pages/Sykler/Hybrid/Herre/HybridHerre2";
import HybridHerre3 from "./pages/Sykler/Hybrid/Herre/HybridHerre3";
import HybridHerre4 from "./pages/Sykler/Hybrid/Herre/HybridHerre4";
import BysykkelDame1 from "./pages/Sykler/Bysykkel/Dame/BysykkelDame1";
import BysykkelDame2 from "./pages/Sykler/Bysykkel/Dame/BysykkelDame2";
import BysykkelDame3 from "./pages/Sykler/Bysykkel/Dame/BysykkelDame3";
import BysykkelDame4 from "./pages/Sykler/Bysykkel/Dame/BysykkelDame4";
import ErrorPage from "./pages/ErrorPage";
import Personvern from "./pages/Personvern";
import "./App.css";


const PersonvernLink = styled.div`
  color: #FFD42D;
  display: inline;
`;

export default function App() {

  return (
    <div className="container">
        <Router>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="sykler" element={<Sykler />} />
              <Route path="verksted" element={<Verksted />} />
              <Route path="deler" element={<Deler />} />
              <Route path="henting" element={<Henting />} />
              <Route path="kontakt" element={<Kontakt />} />
           
              <Route path="herresykler" element={<Herresykler />} />
                  <Route path="hybridHerre1" element={<HybridHerre1 />} />
                  <Route path="hybridHerre2" element={<HybridHerre2 />} />
                  <Route path="hybridHerre3" element={<HybridHerre3 />} />
                  <Route path="hybridHerre4" element={<HybridHerre4 />} />
              <Route path="damesykler" element={<Damesykler />} />
                  <Route path="bysykkelDame1" element={<BysykkelDame1 />} />
                  <Route path="bysykkelDame2" element={<BysykkelDame2 />} />
                  <Route path="bysykkelDame3" element={<BysykkelDame3 />} />
                  <Route path="bysykkelDame4" element={<BysykkelDame4 />} />
              <Route path="product" element={<Product />} />
              <Route path="product2" element={<Product2 />} />
              <Route path="ErrorPage" element={<ErrorPage />} />
              <Route path="Personvern" element={<Personvern />} />
            </Routes>
        </Router>
          <CookieConsent
            location="bottom"
            buttonText="OK"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
          >
            Stensgata Sykkel bruker informasjonskapsler (cookies). Ved å velge "OK" godtar du at vi bruker tredjeparts informasjonskapsler
            for statistikk og markedsføringsformål. For mer informasjon vedr. cookies les <a href="/Personvern"><PersonvernLink>personvernerklæring</PersonvernLink></a>{" "}
        </CookieConsent>
  </div>
  )
};

