import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import styled from "styled-components";
import styles from '../pages/Sykler.module.css';
import Personvernerklaering from '../components/Personvernerklaering';


const Container = styled.div`
`;

const InfoContainer = styled.div`
`;

const Desc = styled.div`
`;

const Title = styled.h1`
  font-weight: 300;
  font-size: 40px;
  padding-bottom: 26px;
`;


const Personvern = () => {
  return (
    <div>
        <Navbar />
        <Personvernerklaering />
        <Footer />
    </div>
  )
}

export default Personvern