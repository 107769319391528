import styled from 'styled-components';
import React from 'react';
import styles from './VerkstedComponent.module.css';
import { PopupButton } from "react-calendly";

const Container = styled.div`
`;

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    transition: all 1.5s ease;
    `

const ImageContainer = styled.div`
`;

const Image = styled.img`
`;

const InfoContainer = styled.div`
`;

const About = styled.div`
    padding-top: 40px;
    padding-bottom: 180px;
`;

const Title = styled.h1`
  font-weight: 300;
  font-size: 40px;
  margin-top: -130px;
  padding-bottom: 10px;
`;

const Title2 = styled.h2`
  font-weight: 300;
  font-size: 34px;
  margin-top: -130px;
  padding-bottom: 10px;
`;

const SubTitle = styled.h3`
    font-size: 24px;
    font-weight: 500;
    color: #101010;
    white-space: pre-line;
    line-height: 1.4;
    padding-top: 4px;
    padding-bottom: 20px;
    letter-spacing: 2px;
`

const Desc = styled.p`
    padding-bottom: 4px;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1.6;
`;

const Popup = styled.div`
`;

const DescBold = styled.p`
    padding-bottom: 4px;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1.6;
`;

const VerkstedComponent2 = () => {
  return (
    <div className="App">
            <Container className={styles.container}>
                  <InfoContainer className={styles.infoContainer}>
                    <br /><br /><br />
                      <Title>Tjenester</Title>
                      <br />
                      <Desc>Grundig service og reparasjoner på de fleste typer av sykler.
                      <br />
                      </Desc>
                      <br />
                      <Desc>Er der problemer med sykkelen? 
                        <br />
                            Kom forbi med den til: Stensgata 7, 0358 Oslo (Bislett).
                            <br />
                            Du får en sms når sykkelen er klar, senest dagen etter.
                            <br />
                            </Desc>
                      <Popup className={styles.popup}>  
                      <PopupButton
                            url="https://calendly.com/stensgata-sykkel"
                            /*
                            * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                            * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                            */
                            rootElement={document.getElementById("root")}
                            text="Book tid her"
                            styles={{
                                height: '500%',
                                width: '50%',
                                top: '50%',
                                padding: '20px',
                                marginTop: '40px',
                                marginBottom: '40px', 
                                fontSize: '22px',
                                letterSpacing: '2px',
                                fontWeight: '500',
                                backgroundColor: '#007746',
                                color: '#fff',
                                border: '#00a2ff',
                                cursor: 'pointer'
                
                              }}
                            pageSettings={{
                                backgroundColor: 'ffffff',
                                hideEventTypeDetails: false,
                                hideLandingPageDetails: false,
                                primaryColor: '00a2ff',
                                textColor: '4d5055'
                              }}
                        /><br /><br /><br />
                        </Popup>

                      <SubTitle>Standard service 499,- inkluderer:</SubTitle><hr className={styles.hr}/>
                      <Desc>• Sikkerhetssjekk</Desc><hr className={styles.hr}/>
                      <Desc>• Gir- og bremsejustering</Desc><hr className={styles.hr}/>
                      <Desc>• Stramming av eventuelle løse deler som kjede, sadel, pedaler, styre, stem og hjullager</Desc><hr hr className={styles.hr}/>
                      <Desc>• Pumping av dekk samt evt. skifting av slanger</Desc><hr className={styles.hr}/>
                      <Desc>• Smøring og rengjøring av drivverk</Desc><hr className={styles.hr}/>
                      <Desc>• Let vask</Desc><hr className={styles.hr}/>
                      <Desc>• Retting av hjul</Desc><hr className={styles.hr}/>
                      <Desc>• Eventuelle deler kommer i tillegg</Desc><hr className={styles.hr}/>
                      <br /><br />
                      <a className={styles.link} href="/Verksted"><SubTitle>Læs mer om service-løsninger her</SubTitle></a>
                      <br /><br /><br /><br />
                  </InfoContainer>
          </Container>
    </div>
  );
};

export default VerkstedComponent2
