import styled from 'styled-components';
import React from 'react';
import styles from './VerkstedComponent.module.css';

const Container = styled.div`
`;

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    transition: all 1.5s ease;
    `

const ImageContainer = styled.div`
`;

const Image = styled.img`
`;

const InfoContainer = styled.div`
`;

const About = styled.div`
    padding-top: 40px;
    padding-bottom: 40px;
`;

const Title2 = styled.h3`
    font-size: 36px;
    font-weight: 600;
    margin-top: -150px;
    letter-spacing: 0px;
    white-space: pre-line;
    line-height: 1.4;
`;

const Title = styled.h1`
  font-weight: 300;
  font-size: 30px;
  margin-top: -130px;
  padding-bottom: 10px;
`;

const SubTitle = styled.h4`
    font-size: 20px;
    font-weight: 400;
    white-space: pre-line;
    line-height: 1.4;
    padding-top: 4px;
    padding-bottom: 20px;
    letter-spacing: 1px;
`

const Desc = styled.p`
    padding-bottom: 4px;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1.6;
`;

const DescBold = styled.p`
    padding-bottom: 4px;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1.6;
`;


const Personvernerklaering = () => {
  return (
    <div className="App">
            <Container className={styles.container}>
                  <InfoContainer className={styles.infoContainer}>
                      <About>
                        <Title>PERSONVERNERKLÆRING FOR STENSGATA SYKKEL</Title>
                        <br />
                          <Desc>Stensgata Sykkel legger stor vekt på hvordan din personlige informasjon håndteres.
                          I denne personvernerklæringen vil vi informere deg om hvordan vi samler inn og behandler dine personlige opplysninger. 
                            <br /><br />
                            <SubTitle> 1. BEHANDLINGSANSVARLIG OG KONTAKTINFORMASJON</SubTitle>
                            Ansvarlig for dine personopplysninger (personlig ansvarlig) er:
                            <br /><br />
                            Firmanavn: Ham Sykler<br />
                            Kontaktadresse: Stensgata 7D, 0358 Oslo<br />
                            E-post: stensgata7@gmail.com<br />
                            Telefonnummer: +47 92292909<br />
                            Organisasjonsnummer: 930 253 227<br />
                            <br />
                            <SubTitle>2. PERSONOPPLYSNINGER SOM LAGRES</SubTitle>
                            Vi lagrer følgende personopplysninger om være kunder: navn, adresse, telefonnummer, e-postadresse. 
                            Vi lagrer i tillegg kjøpshistorikk og adferdsmønster på vår nettside, 
                            dvs. opplysninger om hvordan den enkelte kunde navigerer på siden.
                            <br /><br />
                            <SubTitle>3. FORMÅL MED BEHANDLINGEN</SubTitle>
                            Vi behandler opplysningene for å kunne gjennomføre våre forpliktelser etter avtale med deg. 
                            Vi benytter også opplysningene for å kunne gi deg informasjon, tilbud og service i forbindelse med ditt kjøp via e-post, telefon, SMS og postutsendelser.
                            Vi lagrer i tillegg kjøpshistorikk og adferdsmønster på vår nettside, dvs. opplysninger om hvordan den enkelte kunde navigerer på siden.
                            <br /><br />
                            <SubTitle>4. GRUNNLAGET FOR BEHANDLINGEN</SubTitle>
                            Informasjon om navn, adresse, telefon, e-postadresse benyttes for å oppfylle kjøpsavtalen. Grunnlaget for denne behandlingen er personvernforordningens artikkel Art 6 (b). 
                            Der du har samtykket vil det benyttes også opplysningene til å gi deg informasjon, tilbud og service i forbindelse med ditt kjøp via e-post, telefon og SMS. 
                            Grunnlaget for denne behandlingen er personvernforordningen Art 6 (a).
                            Du kan når som helst takke nei til å motta slik informasjon fra oss. Der du har samtykket til det benyttes kjøpshistorikk og adferdsmønster til å gi anbefalinger og reklame
                            spesielt tilpasset deg som bruker. Grunnlaget for denne behandlingen er personvernforordningen Art 6 (a). 
                            Du kan når som helst trekke tilbake ditt samtykke til slik bruk.
                            <br /><br />
                            <SubTitle>5. INNHENTING AV PERSONOPPLYSNINGER</SubTitle>
                            Vi lagrer de personopplysningene du har avgitt på våre nettsider. 
                            Dette inkluderer innsamlet personopplysninger på vår booking portal Calendly, og via kontakt skjemaer på nettsiden som er via Google tjenestene som er integrert i nettsiden vår. 
                            informasjonskapsler/cookies på våre nettsider for  gi deg som besøker siden best kundeopplevelse og service. Lov om elektronisk kommunikasjon krever at vi informerer våre besøkende om bruk av informasjonskapsler (cookies). 
                            Les mer om bruk av informasjonskapsler her:
                            <br /><br />
                            <SubTitle>6. UTLEVERING AV OPPLYSNINGER TIL TREDJEPARTER</SubTitle>
                            Vi gir aldri dine opplysninger til uautoriserte tredjeparter.
                            Som en del av vårt arbeid vil vi imidlertid motta noen av tjenestene fra utvalgte tjenesteleverandører
                            og gi dem begrenset og strengt overvåket tilgang til noen av våre opplysninger.
                            Før vi videresender personopplysninger til disse partnerselskapene for behandling på vår vegne,
                            vil imidlertid hvert selskap være gjenstand for en revisjon. Alle mottakere av opplysninger må
                            oppfylle de personvernrettslige kravene og bevise deres beskyttelsesnivå med egnede garantier.
                            <br /><br />
                            <SubTitle>7. SLETTING AV PERSONOPPLYSNINGER</SubTitle>
                            Dersom du ønsker å komme med innsigelser på Stensgata Sykkel innsamling, behandling eller bruk av dine
                            personopplysninger i medhold av personopplysningsloven, kan du gi din innsigelse via e-post-adressen angitt ovenfor.
                            Dette kan medføre at tjenesten ikke lenger kan benyttes.
                            Dersom du har innsigelser kan det være at videre bruk av nettsiden ikke er mulig av tekniske grunner.
                            Du har rett til å få spesifikk informasjon fra Stensgata Sykkel om personopplysningene vi har lagret om deg, uten å betale.
                            Du har i tillegg følgende rettigheter:
                            Rett til innsyn – retten til å få vite hvilke personopplysninger som er innsamlet og hvordan de er behandlet
                            Rett til retting – retten til å kreve endringer dersom personopplysninger ikke er oppdaterte
                            Rett til sletting – retten til å kreve sletting av personopplysninger
                            Rett til begrensning – retten til å begrense behandlingen av personopplysninger
                            Rett til dataportabilitet – retten til overføring av personopplysninger i et maskinlesbart format
                            Rett til å nekte – retten til å trekke tilbake et tidligere samtykke til behandling, eller nekte behandling av personopplysninger
                            Rett til å klage til tilsynsmyndigheten – retten til å sende inn en klage mot I.S. hos en tilsynsmyndighet,
                            som enten kan være den ansvarlige tilsynsmyndigheten for I.S. som angitt nedenfor, eller hvilken som helst annen tilsynsmyndighet i EU.
                            <br /><br />
                            <SubTitle>8. RETTIGHETER FOR DEN REGISTRERTE </SubTitle>
                            Vi behandler dine personopplysninger i henhold til personopplysningsloven og gjeldende forskrifter. Det gjøres oppmerksom på at du kan kreve innsyn i og flytting av egne personopplysninger, samt kreve retting eller sletting av opplysninger. 
                            Det kan klages til Datatilsynet på behandling i strid med reglene.
                            <br /><br />
                            <SubTitle>9. PERSONVERNOMBUD </SubTitle>
                            Vi har et personvernombud: Daniel Ham, påser at personopplysningslovens regler om behandling av personopplysninger blir fulgt.
                            <br /><br />
                            <SubTitle>10. INFORMASJONSSIKKERHET  </SubTitle>
                            Vi sikrer dine personopplysninger ved både fysisk og virtuell adgangs- og tilgangskontroll, 
                            samt ved kryptering av sensitive deler av avgitte opplysninger.
                          </Desc>
                      </About>
                  </InfoContainer>
          </Container>
    </div>
  );
};

export default Personvernerklaering
