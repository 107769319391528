import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Categories from '../components/Categories'
import VerkstedComponent2 from '../components/VerkstedComponent2.js'
import Anmeldelser2 from '../components/Anmeldelser2'
import Footer from '../components/Footer/Footer'


const Home = () => {
  return (
    <div>
        <Navbar />
        <Categories />
        <VerkstedComponent2 />
        <Footer />
    </div>
  )
}

export default Home