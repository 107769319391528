import styled from 'styled-components';
import React from 'react';
import styles from './VerkstedComponent.module.css';

const Container = styled.div`
`;

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    transition: all 1.5s ease;
    `

const ImageContainer = styled.div`
`;

const Image = styled.img`
`;

const InfoContainer = styled.div`
`;

const Title2 = styled.h3`
    font-size: 36px;
    font-weight: 600;
    margin-top: -150px;
    color: #101010;
    letter-spacing: 0px;
    white-space: pre-line;
    line-height: 1.4;
`;

const SubTitle = styled.h4`
    font-size: 24px;
    font-weight: 500;
    color: #101010;
    white-space: pre-line;
    line-height: 1.4;
    padding-top: 4px;
    padding-bottom: 20px;
    letter-spacing: 2px;
`

const Desc = styled.p`
    padding-bottom: 4px;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1.6;
`;

const DescBold = styled.p`
    padding-bottom: 4px;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1.6;
`;

const Title = styled.h1`
  font-weight: 300;
  font-size: 40px;
  margin-top: -130px;
  padding-bottom: 10px;
`;


const DelerComponent = () => {
  return (
    <div className="App">
            <Container className={styles.container2}>
                  <InfoContainer className={styles.infoContainer}>
                      <br /><br /><br />
                      <Title>Reservedeler</Title><br /><br />

                      <SubTitle>Bremseklosser</SubTitle><hr className={styles.hr}/>
                      <Desc>Skivebremsebelegg, 2 stk: 99,-</Desc><hr className={styles.hr}/>
                      <Desc>Bremseklosser, V-brems, 2 stk: 49,-</Desc><hr className={styles.hr}/>
                      <br />
                      <SubTitle>Sykkelkjeder</SubTitle><hr className={styles.hr}/>
                      <Desc>Sykkelkjede 8-Del • For maks. 8-delt bakre frikrans: 99,-</Desc><hr className={styles.hr}/>
                      <Desc>Sykkelkjede til navgir • For standardsykler uten gir eller sykler med navgir: 99,-</Desc><hr className={styles.hr}/>
                      <br />
                      <SubTitle>Wire</SubTitle><hr className={styles.hr}/>
                      <Desc>Bremsewire universell: 49,-</Desc><hr className={styles.hr}/>
                      <Desc>Girwire universell: 49,-</Desc><hr className={styles.hr}/>
                      <br /><br /><br /><br />
       
                  </InfoContainer>
          </Container>
    </div>
  );
};

export default DelerComponent
