import { Add, Remove } from "@material-ui/icons";
import styled from "styled-components";
import styles from "./SykkelHenting.module.css"

const Container = styled.div`
  background-color: #f9f9f9;
  border-top: 2px solid #000;
`;

const Wrapper = styled.div`
`;

const ImgContainer = styled.div`
`;

const Image = styled.img`
`;

const InfoContainer = styled.div`
`;

const Title = styled.h1`
  font-weight: 300;
`;

const Desc = styled.p`
  margin: 20px 0px;
`;

const Price = styled.span`
  font-weight: 200;
  font-size: 26px;
`;

const AddContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled.button`
  margin-top: 26px;
  padding: 20px;
  border: 2px solid #007746;
  background-color: #f9f9f9;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  &:hover{
      background-color: #f9f9f9;
  }
`;

const NewLine = styled.div`
  white-space: pre-wrap;
`;


const SykkelHenting = () => {
  return (
    <Container>
      <Wrapper className={styles.wrapper}>
          <Image src="https://stensgata-sykkel.no/images/cykelrod.png" className={styles.image}/>
        <InfoContainer className={styles.infoContainer}>
          <Title>Henting av gamle sykler etter avtale</Title>
          <Desc>
          Vi opkjøper glemte sykler fra borettslag i Oslo og omegn, samt private hentinger. 
          Tar du kontakt med oss, avtaler vi tid og vi henter syklene som ellers ville blitt kastet. 
          Henting er gratis. Ring eller send en mail for nermere avtale.
          </Desc>
          <AddContainer>
            <Button><a href="tel:92292909">Ta kontakt for avtale</a></Button>
          </AddContainer>
        </InfoContainer>
      </Wrapper>
    </Container>
  );
};

export default SykkelHenting;