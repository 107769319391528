import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import DelerComponent from '../components/DelerComponent.js'
import Footer from '../components/Footer/Footer'


const Deler = () => {
  return (
    <div>
      <Navbar />
      <DelerComponent />
      <Footer />
    </div>
  )
}

export default Deler