import styled from 'styled-components';
import React from 'react';
import styles from './VerkstedComponent.module.css';
import { PopupButton } from "react-calendly";
import { border, sizeWidth } from '@mui/system';

const Container = styled.div`
`;

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    transition: all 1.5s ease;
    `

const ImageContainer = styled.div`
`;

const Image = styled.img`
`;

const InfoContainer = styled.div`
`;

const Popup = styled.div`
`;

const Title2 = styled.h3`
    font-size: 36px;
    font-weight: 600;
    margin-top: -150px;
    color: #101010;
    letter-spacing: 0px;
    white-space: pre-line;
    line-height: 1.4;
`;

const SubTitle = styled.h4`
    font-size: 24px;
    font-weight: 500;
    color: #101010;
    white-space: pre-line;
    line-height: 1.4;
    padding-top: 4px;
    padding-bottom: 20px;
    letter-spacing: 2px;
`

const Desc = styled.p`
    padding-bottom: 4px;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1.6;
`;

const DescBold = styled.p`
    padding-bottom: 4px;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1.6;
`;

const Title = styled.h1`
  font-weight: 300;
  font-size: 40px;
  margin-top: -130px;
  padding-bottom: 10px;
`;


const VerkstedComponent = () => {
  return (
    <div className="App">
            <Container className={styles.container2}>
                  <InfoContainer className={styles.infoContainer}>
                      <br /><br /><br />
                      <Title>Service</Title><br />
                      <Desc>Kom forbi med sykkelen til: Stensgata 7, 0358 Oslo (Bislett).
                            <br />
                            Du får en sms når sykkelen er klar, senest dagen etter.</Desc>
                            <br />
                      <Popup className={styles.popup}>  
                      <PopupButton
                            url="https://calendly.com/stensgata-sykkel"
                            /*
                            * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                            * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                            */
                            rootElement={document.getElementById("root")}
                            text="Book tid her"
                            styles={{
                                height: '500%',
                                width: '50%',
                                top: '50%',
                                padding: '20px',
                                marginTop: '40px',
                                marginBottom: '40px', 
                                fontSize: '22px',
                                letterSpacing: '2px',
                                fontWeight: '500',
                                backgroundColor: '#007746',
                                color: '#fff',
                                border: '#00a2ff',
                                cursor: 'pointer'
                
                              }}
                            pageSettings={{
                                backgroundColor: 'ffffff',
                                hideEventTypeDetails: false,
                                hideLandingPageDetails: false,
                                primaryColor: '00a2ff',
                                textColor: '4d5055'
                              }}
                        /><br /><br /><br />
                        </Popup>

                      <SubTitle>Standard service 499,- inkluderer:</SubTitle><hr className={styles.hr}/>
                      <Desc>• Sikkerhetssjekk</Desc><hr className={styles.hr}/>
                      <Desc>• Gir- og bremsejustering</Desc><hr className={styles.hr}/>
                      <Desc>• Stramming av eventuelle løse deler som kjede, sadel, pedaler, styre, stem og hjullager</Desc><hr hr className={styles.hr}/>
                      <Desc>• Pumping av dekk samt evt. skifting av slanger</Desc><hr className={styles.hr}/>
                      <Desc>• Smøring og rengjøring av drivverk</Desc><hr className={styles.hr}/>
                      <Desc>• Let vask</Desc><hr className={styles.hr}/>
                      <Desc>• Retting av hjul</Desc><hr className={styles.hr}/>
                      <Desc>• Eventuelle deler kommer i tillegg</Desc><hr className={styles.hr}/>
                      <br /><br />
                      <SubTitle>Priser på enkle service </SubTitle>
                      <DescBold>Bremser </DescBold><hr className={styles.hr}/>
                      <Desc>• Skifte bremseklosser 200,- </Desc><hr className={styles.hr}/>
                      <Desc>• Skifte skivebremseklosser 200,- </Desc><hr className={styles.hr}/>
                      <Desc>• Lufte skivebrems, per brems 250,- </Desc><hr className={styles.hr}/>
                      <Desc>• Justere bremser 200,- </Desc><hr className={styles.hr}/>
                      <br />
                      <DescBold>Gir </DescBold><hr className={styles.hr}/>
                      <Desc>• Justere gir: 250,- </Desc><hr className={styles.hr}/>
                      <Desc>• Bytting av vaier: 200,- </Desc><hr className={styles.hr}/>
                      <Desc>• Bytting av krank eller bakgir: 200,-</Desc><hr className={styles.hr}/>
                      <Desc>• Bytting av girøre: 150,-</Desc><hr className={styles.hr}/><br />
                      <DescBold>Drivverk </DescBold><hr className={styles.hr}/>
                      <Desc>• Bytting av kjede: 200,- </Desc><hr className={styles.hr}/>
                      <Desc>• Bytting av kranksett: 250,- </Desc><hr className={styles.hr}/>
                      <Desc>• Bytting av kassett: 200,- </Desc><hr className={styles.hr}/>
                      <Desc>• Bytting av kranklager: 350,- </Desc><hr className={styles.hr}/><br />
                      <DescBold>Hjul og dekk </DescBold><hr className={styles.hr}/>
                      <Desc>• Bytting av dekk: 200,- </Desc><hr className={styles.hr}/>
                      <Desc>• Bytting av slange: 200,- </Desc><hr className={styles.hr}/>
                      <Desc>• Justering av nav: 200,- </Desc><hr className={styles.hr}/>
                      <Desc>• hjulretting: 200,- </Desc><hr className={styles.hr}/>
                      <Desc>• Bytting av eiker: 250,-</Desc><hr className={styles.hr}/>
                      <Desc>• Skifte til vinterdekk 2 stk: 400,- </Desc><hr className={styles.hr}/><br />
                      <DescBold>Styre </DescBold><hr className={styles.hr}/>
                      <Desc>• Bytting av styre: 250,-</Desc><hr className={styles.hr}/>
                      <Desc>• Bytting av stem: 200,-</Desc><hr className={styles.hr}/>
                      <Desc>• Bytting av styrelager: 350,-</Desc><hr className={styles.hr}/>
                      <Desc>• Bytting av styreteip: 250,-</Desc><hr className={styles.hr}/>
                      <DescBold>Tilbehør </DescBold><hr className={styles.hr}/>
                      <Desc>• Montering av kurv: 200,-</Desc><hr className={styles.hr}/>
                      <Desc>• Montering av skjermsett: 250,-</Desc><hr className={styles.hr}/>
                      <Desc>• Montering av støtte: 200,-</Desc><hr className={styles.hr}/>
                      <Desc>• Montering av bagasjebrett: 250,-</Desc><hr className={styles.hr}/>
                      <br /><br /><br /><br />
                  </InfoContainer>
          </Container>
    </div>
  );
};

export default VerkstedComponent
