import styled from 'styled-components';
import React from 'react';
import styles from './Anmeldelser2.module.css';

const Container = styled.div`
`;

const Wrapper = styled.div`
    height: 100%;
    display: flex;
    transition: all 1.5s ease;
    `

const ImageContainer = styled.div`
`;

const Image = styled.img`
`;

const InfoContainer = styled.div`
`;

const About = styled.div`
    padding-top: 40px;
    padding-bottom: 180px;
`;

const Title = styled.h1`
  font-weight: 300;
  font-size: 40px;
  margin-top: -130px;
  padding-bottom: 10px;
`;

const Title2 = styled.h2`
  font-weight: 300;
  font-size: 34px;
  margin-top: -130px;
  padding-bottom: 10px;
  color: #f9f9f9;
`;

const SubTitle = styled.h3`
    font-size: 26px;
    font-weight: 300;
    color: #101010;
    white-space: pre-line;
    line-height: 1.4;
    padding-top: 4px;
    padding-bottom: 20px;
    letter-spacing: 2px;
`

const Desc = styled.p`
    padding-bottom: 4px;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 1.6;
    color: #f9f9f9;
`;

const Popup = styled.div`
`;

const DescBold = styled.p`
    padding-bottom: 4px;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 1.6;
`;

const Anmeldelser2 = () => {
  return (
    <div className="App">
            <Container className={styles.container}>
                  <InfoContainer className={styles.infoContainer}>
                    <br /><br /><br />
                      <Title2>Anmeldelser fra Finn.no</Title2>
                      <br />
                      <Desc>Service og reparasjoner på de fleste typer av sykler.
                                <br />
                                Vi selger også brukte velkjørende kvalitets-sykler.
                          </Desc>
                      <br />
                      <Desc>Har du et problem med sykkelen din? 
                        <br />
                            Kom forbi med sykkelen til: Stensgata 7, 0358 Oslo (Bislett).
                            <br />
                            Du får sms når sykkelen er klar, senest dagen etter.
                            <br /><br />
                            Er du usikker på hvor mye det vil koste, kan vi ta imot 
                            <br />
                            sykkelen og lage et prisoverslag før vi gjør noe med den. </Desc>
                  </InfoContainer>
          </Container>
    </div>
  );
};

export default Anmeldelser2
