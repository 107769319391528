import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import VerkstedComponent from '../components/VerkstedComponent.js'
import Footer from '../components/Footer/Footer'


const Verksted = () => {
  return (
    <div>
      <Navbar />
      <VerkstedComponent />
      <Footer />
    </div>
  )
}

export default Verksted