export const sliderItems = [
    {
      id: 1,
      img: "https://i.ibb.co/XsdmR2c/1.png",
      title: "",
      desc: "",
      bg: "",
    },
    {
      id: 2,
      img: "https://i.ibb.co/DG69bQ4/2.png",
      title: "Brukte kvalitetssykler",
      desc: "Gi´ din mor en ny brukt sykkel.",
      bg: "ffe4b5",
    },
    {
      id: 3,
      img: "https://i.ibb.co/cXFnLLV/3.png",
      title: "Brukte kvalitetssykler",
      desc: "Gi´en sykkel til en du holder av.",
      bg: "add8e6",
    },
  ];

  export const categories = [
    {
      id: 1,
    //  img: "https://lp-cms-production.imgix.net/2022-01/Cycle%20Italy.jpg?auto=format&q=40&ar=16%3A9&fit=crop",
      img: "https://stensgata-sykkel.no/images/bg-stensgata_7.png",
      title: "Tilbud",
    },
  ];

  export const popularProducts = [
    {
      id:1,
      img:"https://stensgata-sykkel.no/images/hybrid/merida.jpeg",
      title: "Merida CrossWay",
      link: "/Product",
    },
    {
      id:2,
      img:"https://stensgata-sykkel.no/images/hybrid/merida.jpeg",
      title: "Merida CrossWay",
      link: "/Product",
    },
    {
      id:3,
      img:"https://stensgata-sykkel.no/images/bysykler/4462bc_d5d1a229ad524de9947a27bf5cfd2038_mv2.jpeg",
      title: "DBS Classic",
      link: "https://stensgata-sykkel.no/images/bysykler/4462bc_d5d1a229ad524de9947a27bf5cfd2038_mv2.jpeg",
    },
    {
      id:4,
      img:"https://stensgata-sykkel.no/images/hybrid/WEB_Image_DBS_Allroad_4_1x8_55__yrd381__01_27940_preview1942690515_plid_212971.jpeg",
      title: "DBS Allroad",
    },
    {
      id:5,
      img:"https://stensgata-sykkel.no/images/hybrid/WEB_Image_Giant_Liv_Rove_3_2022_S__rove_3_disc-645544412_plid_214446.jpeg",
      title: "Giant Liv Rove 3",
    },
    {
      id:6,
      img:"https://stensgata-sykkel.no/images/hybrid/WEB_Image_DBS_Allroad_4_1x8_55__yrd381__01_27940_preview1942690515_plid_212971.jpeg",
      title: "DBS Allroad",
    },
    {
      id:7,
      img:"https://stensgata-sykkel.no/images/hybrid/merida.jpeg",
      title: "Merida CrossWay",
    },
    {
      id:8,
      img:"https://stensgata-sykkel.no/images/bysykler/WEB_Image_Pelago_Brooklyn_7S_Helene_Grey__7-gir__M_pelago_brooklyn_helene_grey_driveside-102057125186_plid_195911.jpeg",
      title: "Pelago Brooklyn 7S",
    },
  ]

  export const herreSykler = [
    {
      id:1,
      img:"https://stensgata-sykkel.no/images/hybrid/DBS_Hybrid_herre1.jpg",
      title: "DBS 3 Speed",
      link: "/HybridHerre1",
      price: "1600 ,-",
      sold: "",
    },
    {
      id:2,
      img:"https://stensgata-sykkel.no/images/hybrid/Merida-hybrid_herre2.png",
      title: "Merida CrossWay",
      link: "/HybridHerre2",
      price: "1200 ,-",
      sold: "Solgt",
    },
    {
      id:3,
      img:"https://stensgata-sykkel.no/images/hybrid/DBS-Logic-hybrid3.png",
      title: "DBS Logic",
      link: "HybridHerre3",
      price: "1300 ,-",
      sold: "",
    },
    {
      id:4,
      img:"https://stensgata-sykkel.no/images/hybrid/merida-crossway-XL.png",
      title: "Merida CrossWay XL",
      link: "/HybridHerre4",
      price: "2500 ,-",
      sold: "Solgt",
    },
  ]

  export const dameSykler = [
    {
      id:1,
      img:"https://stensgata-sykkel.no/images/bysykler/bysykkel1-kildemoes.png",
      title: "Kildemoes 3 gir",
      link: "/BysykkelDame1",
      price: "1000 ,-",
      sold: "",
    },
    {
      id:2,
      img:"https://stensgata-sykkel.no/images/bysykler/bysykkel2-dbs-hvid.png",
      title: "DBS Classic 7 gir",
      link: "/BysykkelDame2",
      price: "1600 ,-",
      sold: "",
    },
    {
      id:3,
      img:"https://stensgata-sykkel.no/images/bysykler/bysykkel3-Apollo.png",
      title: "Apollo 3 gir",
      link: "/BysykkelDame3",
      price: "1300 ,-",
      sold: "Solgt",
    },
    {
      id:4,
      img:"https://stensgata-sykkel.no/images/bysykler/bysykkel4-dbs-26.png",
      title: "DBS Classic 3 gir",
      link: "/BysykkelDame4",
      price: "1000 ,-",
      sold: "Solgt",
    },
  ]

  export const frontpageProducts = [
    {
      id:1,
      img:"https://stensgata-sykkel.no/images/hybrid/WEB_Image_Trek_Dual_Sport_2_2022_Mulsanne_Blue__M__dual_sport_2_blue686793724_plid_216206.jpeg",
      title: "HYBRID",
    },
    {
      id:2,
      img:"https://stensgata-sykkel.no/images/bysykler/4462bc_d5d1a229ad524de9947a27bf5cfd2038_mv2.jpeg",
      title: "BYSYKLER",
    },
    {
      id:3,
      img:"https://stensgata-sykkel.no/images/terreng/terreng.jpeg",
      title: "TERRENG",
    },
    {
      id:4,
      img:"https://stensgata-sykkel.no/images/landevei/landevei.jpeg",
      title: "LANDEVEI",
    },
  ]