import React, { useState } from "react";
import { Link } from 'react-router-dom';
import "./Navbar.css";
import Logo from './logo-gray.png';


const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    return ( 
        <div className="Navbar">
            <span className="nav-logo"><a href="/"><img src={Logo} style={{ height: 100, width: 86}}
              alt="website logo"></img></a></span>
            <div className={`nav-items ${isOpen && "open"}`}>
                <Link to='/Verksted'>Verksted</Link>
                <Link to='/Deler'>Reservedeler</Link>
                <Link to='/Henting'>Henting</Link>
                <Link to='/Kontakt'>Kontakt</Link>
            </div>
            <div
            className={`nav-toggle ${isOpen && "open"}`}
            onClick={() => setIsOpen(!isOpen)}
        >
            <div className="bar"></div>
        </div>
    </div>
    )
}

export default Navbar