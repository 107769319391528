import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import BysykkelDame1 from '../components/Sykler/Damesykler/BysykkelDame1'
import BysykkelDame2 from '../components/Sykler/Damesykler/BysykkelDame2'
import BysykkelDame3 from '../components/Sykler/Damesykler/BysykkelDame3'
import BysykkelDame4 from '../components/Sykler/Damesykler/BysykkelDame4'
import styled from "styled-components";
import styles from '../pages/Sykler.module.css'

const Container = styled.div`
`;

const InfoContainer = styled.div`
`;

const Desc = styled.div`
`;

const Title = styled.h1`
  font-weight: 300;
  font-size: 40px;
  padding-bottom: 26px;
`;


const Sykler = () => {
  return (
    <div>
        <Navbar />
        <Container className={styles.container}>
          <InfoContainer className={styles.infoContainer}>
             <Title>Brukte kvalitets damesykler</Title>
          </InfoContainer>
        </Container>
        <BysykkelDame2 />
        <BysykkelDame1 />
        <BysykkelDame3 />
        <BysykkelDame4 />
        <Footer />
    </div>
  )
}

export default Sykler