import styled from "styled-components";
import styles from './CategoryItem.module.css';

const Container = styled.div`
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(90%);
`;
const Info = styled.div`
`;

const Title = styled.h1`
    color: #000;
`;

const Green = styled.div`
    color: #007746;
`;

const Box = styled.div`
`;

const Box2 = styled.div`
    background: #fff;
    padding-top: 14px;
    padding-left: 14px;
    padding-right: 14px;
    padding-bottom: 14px;
    margin-bottom: 54px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
`;


const Button = styled.button`
    padding: 16px;
    background-color: #000000;
    color: #ffffff;
    margin-top: 10px;
    margin-left: 22px;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    border-width: 4px;
    border-color: #000;
    border-radius: 30px;
`;

const ContactItemLink = styled.div`
  text-decoration: underline;
  white-space: nowrap;
`;

const CategoryItem = ({item}) => {
  return (
    <Container className={styles.container}>
        <Image src={item.img}/>
        <Info className={styles.info}>
                <Title className={styles.title}>Velkommen til <br /><Green>Stensgata sykkel</Green></Title>
                <Title className={styles.title2}>Få sykkelen kjøreklar!</Title>
                <Title className={styles.title2}>Standard service: 499,-</Title>
                <Title className={styles.title3}><a href="tel:92292909">92292909</a> - Verksted i bakgården</Title>
        </Info>
    </Container>
  )
}

export default CategoryItem
