import {
    MailOutline,
    Phone,
    Room,
  } from "@material-ui/icons";
import styled from "styled-components";
import styles from './Footer.module.css';
import * as React from 'react';

const Container = styled.div`
`;

const Left = styled.div`
`;

const Center = styled.div`
  flex: 1;
  padding: 20px;
`;

const Title = styled.h3`
  margin-bottom: 30px;
  font-size: 18px;
`;

const Right = styled.div`
  flex: 1;
  padding: 20px;
`;

const ContactItem = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
`;

const ContactItemLink = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  text-decoration: underline;
`;


const Footer = () => {
    return (
      <Container className={styles.container}>
        <Left className={styles.left}>
          <ContactItem>
            <p><strong>Kontakt</strong></p>
          </ContactItem>
          <ContactItem>
            <Room style={{marginRight:"10px"}}/> 
            <a
            target="_blank"
            rel="noreferrer"
            href='https://www.google.com/maps/place/Stensgata+7,+0358+Oslo,+Norge/@59.9305208,10.7304856,17z/data=!3m1!4b1!4m5!3m4!1s0x46416e760c642995:0x92e8d2db4352a25!8m2!3d59.9305208!4d10.7326743'>
            Stensgata 7D, 0358 Oslo
          </a>
          </ContactItem>
          <ContactItem>
            <Phone style={{marginRight:"10px"}}/><a href="tel:92292909"> +47 92292909</a>
          </ContactItem>
          <ContactItem>
            <MailOutline style={{marginRight:"10px"}} /> stensgata7@gmail.com
          </ContactItem>
          <br/>
          <ContactItem>
            <p><strong>Åpningstider</strong></p>
          </ContactItem>
          <ContactItem>
            <p>man-fre: 09.30 - 18.00</p>
          </ContactItem>
          <ContactItem>
            <p>lør: 11.00-16.00</p>
          </ContactItem>
          <ContactItem>
            <p>Ferielukket fra: 07.09.2024 - 11.09.2024</p>
          </ContactItem>
          <ContactItem>
            <p>Org. nr: 930 253 227</p>
          </ContactItem>
          <ContactItemLink>
            <p><a href="/Personvern">Personvernerklæring</a></p>
          </ContactItemLink>
          <ContactItemLink>
            <p><a href="https://www.forbrukertilsynet.no/lov-og-rett/veiledninger-og-retningslinjer/veiledning-standard-salgsbetingelser-forbrukerkjop-varer-internett">Salgsvilkår</a></p>
          </ContactItemLink>
        </Left>
        <Center>
        </Center>
        <Right>

        </Right>
      </Container>
    );
  };

  
  export default Footer;