import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import HybridHerre1 from '../components/Sykler/Herresykler/HybridHerre1'
import HybridHerre2 from '../components/Sykler/Herresykler/HybridHerre2'
import HybridHerre3 from '../components/Sykler/Herresykler/HybridHerre3'
import HybridHerre4 from '../components/Sykler/Herresykler/HybridHerre4'
import styled from "styled-components";
import styles from '../pages/Sykler.module.css';

const Container = styled.div`
`;

const InfoContainer = styled.div`
`;

const Desc = styled.div`
`;

const Title = styled.h1`
  font-weight: 300;
  font-size: 40px;
  padding-bottom: 26px;
`;


const Sykler = () => {
  return (
    <div>
        <Navbar />
        <Container className={styles.container}>
          <InfoContainer className={styles.infoContainer}>
             <Title>Brukte kvalitets herresykler</Title>
          </InfoContainer>
        </Container>
        <HybridHerre1 />
        <HybridHerre4 />
        <HybridHerre2 />
        <HybridHerre3 />
        <Footer />
    </div>
  )
}

export default Sykler